import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { CgDanger } from "react-icons/cg";

import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getAuthData } from "../../redux/slices/AuthSlice/authSlice";

function LoginScreen() {
  const [showPass, setShowPass] = useState(false);
  const { register, errors, handleSubmit } = useForm();
  const dispatch = useDispatch();

  const authData = useSelector((state) => state?.authData);
  const { error } = authData;
  console.log(error);

  const onSubmit = (apiData, e) => {
    e.preventDefault();
    dispatch(getAuthData(apiData));
  };

  return (
    <main class="main_login">
      <div class="login_form_div">
        <div>
          <img class="logo_img" src="/wasa.webp" alt="logo" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="text-white mb-8">Login</h2>
          {error && (
            <span className="text-white text-[12px] font-[400] ">
              <p className="flex items-center space-x-1 bg-red-400 p-4">
                <CgDanger size={13} /> {error}
              </p>
            </span>
          )}
          <div class="input-field my-2">
            <div>
              <input
                type="text"
                name="username"
                id="username"
                placeholder="Enter username"
                className="px-3 py-2"
                autoComplete="off"
                ref={register({
                  required: {
                    value: true,
                    message: "This Field is Required",
                  },
                })}
              />
            </div>
          </div>
          {errors?.username?.message && (
            <span className="text-red-400 text-[12px] font-[400]">
              <p className="flex items-center space-x-1">
                <CgDanger size={13} /> {errors?.username?.message}
              </p>
            </span>
          )}
          <div class="input-field my-2">
            <div className="w-full my-3">
              <div className="flex items-center ">
                <input
                  type={showPass ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="Enter password"
                  className="px-3 py-2"
                  autoComplete="off"
                  ref={register({
                    required: {
                      value: true,
                      message: "This Field is Required",
                    },
                  })}
                />
                <div className="bg-[#E8F0FE] p-3 rounded-r-md text-gray-600">
                  {showPass ? (
                    <FaEye
                      className="cursor-pointer"
                      onClick={() => setShowPass(!showPass)}
                    />
                  ) : (
                    <FaEyeSlash
                      className="cursor-pointer"
                      onClick={() => setShowPass(!showPass)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {errors?.password?.message && (
            <span className="text-red-400 text-[12px] font-[400]">
              <p className="flex items-center space-x-1">
                <CgDanger size={13} /> {errors?.password?.message}
              </p>
            </span>
          )}
          <div class="flex justify-end">
            <button class="login_btn" type="submit">
              Log In
            </button>
          </div>
        </form>
      </div>
      <p class="footer_text">
        © Designed & Developed By
        <a href="https://www.suffixit.com/" target="_blank" rel="noreferrer">
          Suffix IT Ltd
        </a>
      </p>
    </main>
  );
}

export default LoginScreen;
