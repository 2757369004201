import React from "react";
import { GridLoader } from "react-spinners";

function FullScreenLoader() {
  return (
    <div className="h-screen flex justify-center items-center bg-black/5">
      <GridLoader color="#17228A" size={20} />
    </div>
  );
}

export default FullScreenLoader;
