import React, { useEffect } from "react";
import swal from "sweetalert";

function FallBackError() {
  useEffect(() => {
    swal({
      title: "Something bad happened!",
      text: "Clear cache and reload by pressing CTRL+SHIFT+R",
      icon: "error",
      buttons: ["Cancel", "Try again"],
      dangerMode: true,
    }).then((clicked) => {
      var pathName = window?.location?.pathname?.split("/");
      if (clicked && pathName?.[1] === "qr-verification") {
        window.location.assign("/qr-verification");
      } else {
        window.location.assign("/");
      }
    });
  }, []);

  return <div />;
}

export default FallBackError;
