export const baseUrl = "https://wasaapi.suffixit.com";
export const authUrl = "https://wasaauthapi.suffixit.com";
export const redirectUrl = "https://wasa.suffixit.com";

// export const baseUrl = "http://172.168.21.75:14000";
// export const authUrl = "http://172.168.21.75:14001";
// export const redirectUrl = "http://172.168.21.45:3001";

// export const baseUrl = "http://172.168.20.218:14000";
// export const authUrl = "http://172.168.20.218:14001";
// export const redirectUrl = "http://172.168.21.45:3002";
