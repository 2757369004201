import PropTypes from "prop-types";
import ThemeProvider from "./ThemeContext";

function ContextProvider({ children = null }) {
  return <ThemeProvider>{children}</ThemeProvider>;
}

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContextProvider;
