// export const baseurl = "http://172.168.21.75:14000";
// export const BASEURL = "http://172.168.21.75:14000";
// export const APP_BASE_URL = "http://172.168.21.45:3001";

// export const baseurl = "http://172.168.20.218:14000";
// export const BASEURL = "http://172.168.20.218:14000";
// export const APP_BASE_URL = "http://172.168.21.45:3002";

export const baseurl = "https://wasaapi.suffixit.com";
export const BASEURL = "https://wasaapi.suffixit.com";
export const APP_BASE_URL = "https://wasa.suffixit.com/";
