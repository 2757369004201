import React, { useEffect, useState } from "react";
import OfflineAlert from "./OfflineAlert";

function Offline() {
  const [isOnline, setIsOnline] = useState(true);
  useEffect(() => {
    const handleOnlineOrOfflineStatus = (e) => {
      if (e.type === "online") {
        setIsOnline(true);
      } else if (e.type === "offline") {
        setIsOnline(false);
      }
    };
    window.addEventListener("online", handleOnlineOrOfflineStatus);
    window.addEventListener("offline", handleOnlineOrOfflineStatus);
    return () => {
      window.removeEventListener("online", handleOnlineOrOfflineStatus);
      window.removeEventListener("offline", handleOnlineOrOfflineStatus);
    };
  });

  if (isOnline) {
    return <div />;
  }

  return (
    <div>
      <h5 className="m-0 py-[3px] text-center font-[600] text-[13px] bg-[#ffc107] text-slate-50">
        No internet connection available.
      </h5>
      <OfflineAlert isOnline={isOnline} />
    </div>
  );
}

export default Offline;
