/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import axios from "axios";
import jwtDecode from "jwt-decode";
import { baseurl } from "../../config/baseURL";
import {
  getTokenDataSuccessFully,
  getUserInfoDataSuccessFully,
  logOut,
} from "../../redux/slices/AuthSlice/authSlice";
import store from "../../redux/store";
import { authUrl } from "../../commom/baseUrls";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";

const base64UrlEncode = (str) => {
  let encoded = btoa(str)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
  return encoded;
};

const axiosAuthInstance = axios.create({
  baseURL: baseurl,
  timeout: 500000,
  message: "It's took too long to get response from server",
});

// Add a request interceptor
axiosAuthInstance.interceptors.request.use(async (config) => {
  try {
    const token = JSON.parse(localStorage.getItem("token"));
    console.log("token", token);
    if (token) {
      config.headers.Authorization = `Bearer ${token?.access_token}`;
    }
    return config;
  } catch (error) {
    // Handle errors
    console.error("Error fetching token from LocalStorage:", error);
    return Promise.reject(error);
  }
});

// Add a response interceptor
axiosAuthInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const { refresh_token } = await JSON.parse(localStorage.getItem("token"));
    const { exp } = await JSON.parse(localStorage.getItem("userInfo"));
    if (error?.response?.status === 401) {
      originalRequest._retry = true;
      console.log("error", error);

      if (refresh_token) {
        try {
          const { data } = await axios({
            method: "POST",
            url: `${authUrl}/oauth2/token`,
            headers: {
              Authorization: `Basic ${base64UrlEncode("oidc-client:1234")}==`,
            },
            params: {
              grant_type: "refresh_token",
              refresh_token: refresh_token,
            },
          });
          if (data) {
            store.dispatch(getTokenDataSuccessFully(data));
            localStorage.setItem("token", JSON.stringify(data));

            const decodeToken = jwtDecode(data?.access_token);
            store.dispatch(getUserInfoDataSuccessFully(decodeToken));
            localStorage.setItem("userInfo", JSON.stringify(decodeToken));

            originalRequest.headers.Authorization = `Bearer ${data?.access_token}`;
            return axiosAuthInstance(originalRequest);
          }
        } catch (err) {
          localStorage.removeItem("token");
          localStorage.removeItem("userInfo");
          Cookies.remove("JSESSIONID");
          window.location.href = "/";
          return Promise.reject(err);
          store.dispatch(logOut());
        }
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        Cookies.remove("JSESSIONID");
        window.location.href = "/";
        return Promise.reject(error.response || error.message);
        store.dispatch(logOut());
      }
    }

    return Promise.reject(error.response || error.message);
  }
);

export default axiosAuthInstance;
