import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter as Router } from "react-router-dom";
import FallBackError from "./components/FallBackError";
import Offline from "./components/Offline";
import StartUpScreen from "./containers/StartUpScreen";
import ContextProvider from "./context";

function App() {
  const handleBoundaryError = (error, errorInfo) => {
    console.log("Error Occurred ", error, errorInfo);
  };
  return (
    <ContextProvider>
      <Router>
        <Offline />
        <ErrorBoundary
          FallbackComponent={FallBackError}
          onError={handleBoundaryError}
        >
          <StartUpScreen />
        </ErrorBoundary>
      </Router>
    </ContextProvider>
  );
}

export default App;
