import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/AuthSlice/authSlice";
import meterListSlice from "./slices/AuthSlice/meterListSlice";

const store = configureStore({
  reducer: {
    authData: authReducer,
    meterList: meterListSlice,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
