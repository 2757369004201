import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { authUrl, redirectUrl } from "../commom/baseUrls";
import {
  getAuthData,
  getReloadAuthData,
} from "../redux/slices/AuthSlice/authSlice";
import FullScreenLoader from "../shared/loader/FullScreenLoader";
import LoginScreen from "../screens/Auth/LoginScreen";
const WasaApplication = lazy(() => import("./WasaApplication"));
const AdminApplication = lazy(() => import("./AdminApplication"));

const QrCheckScreen = lazy(() =>
  import("../screens/MobileScreen/QrCheckScreen")
);

function StartUpScreen() {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state?.authData);

  const location = useLocation();

  useEffect(() => {
    if (
      !(pathName[1] === "qr-verification" && typeof pathName[2] === "string") &&
      !authData?.token?.access_token
    ) {
      const token = JSON.parse(localStorage.getItem("token"));

      if (token?.access_token) {
        dispatch(getReloadAuthData());
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        if (code) {
          dispatch(getAuthData(code));
        } else {
          // window.location.href = `${authUrl}/oauth2/authorize?response_type=code&client_id=oidc-client&scope=openid&redirect_uri=${redirectUrl}&code_challenge=n4bQgYhMfWWaL-qgxVrQFaO_TxsrC4Is0V1sFbDwCgg&code_challenge_method=S256`;
        }
      }
    }
  }, []);

  // check if user is logged in or not

  var pathName = window?.location?.pathname?.split("/");
  // var secondLevelLocation = pathArray[0];
  // console.log(typeof parseInt(pathName[2]));
  // console.log(pathName);

  if (pathName[1] === "qr-verification" && typeof pathName[2] === "string") {
    return (
      <Suspense fallback={<FullScreenLoader />}>
        <QrCheckScreen number={pathName[2]} />
      </Suspense>
    );
  }

  // if user is logged in
  if (authData?.userInfo && authData?.token?.access_token) {
    return (
      <Suspense fallback={<FullScreenLoader />}>
        {authData?.userInfo?.roles?.includes("ROLE_ADMIN") ? (
          <AdminApplication />
        ) : (
          <WasaApplication />
        )}
      </Suspense>
    );
  }
  // if (authData?.userInfo?.authorities?.includes("ROLE_SUPER_ADMIN")) {
  //   return <DemoApplication />;
  // }

  return <LoginScreen />;
}
export default StartUpScreen;
