/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { baseurl } from "../../../config/baseURL";
import axiosAuthInstance from "../../../helpers/axiosInstance/axiosAuthInstance";

const base64UrlEncode = (str) => {
  let encoded = btoa(str)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
  return encoded;
};

// Slice Creates
const meterListSlice = createSlice({
  name: "meterList",
  initialState: {}, // initial state
  reducers: {
    startLoadingMeterList: (state) => {
      state.loading = true;
      state.error = "";
    },
    getMeterListSuccessFully: (state, action) => {
      state.meterList = action?.payload;
      state.loading = false;
      state.error = "";
    },
    failedLoadingMeterList: (state, action) => {
      state.error = action?.payload;
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  startLoadingMeterList,
  getMeterListSuccessFully,
  failedLoadingMeterList,
} = meterListSlice.actions;

// Api call for login
export const getMeterList = () => async (dispatch) => {
  dispatch(startLoadingMeterList());
    try {
      const { data } = await axiosAuthInstance.get(`${baseurl}/dashboard/admin/getMeterList`);
      if (data) {
        dispatch(getMeterListSuccessFully(data));
      }
    } catch (error) {
      dispatch(
        failedLoadingMeterList(
          error?.response && error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        )
      );
    }
  
};


export default meterListSlice.reducer;
